import React from "react"

import Layout from "../components/layout"
import { Wrapper } from "../components/styles/components"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"

const NotFoundPage = ({ location }) => {
  const { t } = useTranslation("404")

  //* This condition renders the component blank during gatsby build, forcing it to fully respect all logic when the page loads.
  //* Check if this might have negative SEO impact...
  if (typeof window === `undefined`) {
    return <></>
  }

  return (
    <Layout>
      <Seo title={"404: " + t("notFound")} location={location} />
      <Wrapper>
        <h1>{t("notFound")}</h1>
        <p>{t("notFoundMessage")}</p>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage
